import { EmptyState } from '@/app/atoms/empty-state';
import { VehicleDetails } from '@/app/common/components/molecules/vehicle-details';
import { SupplySummaryToolbar } from '@/app/features/supply/components/supply-summary-toolbar';
import { useOrderRequestsDrawer } from '@/app/features/supply/hooks/use-order-request-drawer';
import {
	AddItemPayload,
	DraftOrderSelection,
	OrderRequestModel
} from '@/app/features/supply/models';
import { formatAddress } from '@/app/utils/address';
import { formatPrice } from '@/app/utils/currency';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { Transition } from '@headlessui/react';
import { Button } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { Job, JobPart } from '@sdk/lib';
import { cloneElement, useMemo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Drawer } from 'vaul';
import { useNoSupplyParts } from '../../hooks/use-no-supply-parts';
import { useOrderRequestAggregation } from '../../hooks/use-order-request-aggregation';
import { getLatestPossibleDeliveryDateFromSelection, onAddExternalItem } from '../../order-request';
import { AddExternalItemModal } from '../add-external-item-modal';
import { OrderRequestBuilder } from '../order-request-builder';
import { OrderRequestNoSupply } from '../order-request-no-supply';

type DraftOrderSelectionDrawerProps = InheritableElementProps<
	'div',
	{
		job: Job;
		form: UseFormReturn<DraftOrderSelection>;
		noSupplyParts: JobPart[];
		onSave: (selection: DraftOrderSelection) => void;
		onRequestOrders: (selection: DraftOrderSelection) => void;
		onCancel: (order: OrderRequestModel) => void;
	}
>;

export const OrderRequestsDrawer = ({
	form,
	job,
	noSupplyParts,
	onSave,
	onRequestOrders,
	onCancel
}: DraftOrderSelectionDrawerProps) => {
	const selection = form.watch();
	const slider = useOrderRequestsDrawer();
	const { totalItems, totalPrice, identities } = useOrderRequestAggregation(selection);
	const noSupply = useNoSupplyParts(noSupplyParts, identities);
	const ordersList = useMemo(
		() =>
			Object.values(selection.draft_orders)
				.sort((a, b) => {
					if (a.status === 'Draft' && b.status !== 'Draft') {
						return -1;
					}
					if (a.status !== 'Draft' && b.status === 'Draft') {
						return 1;
					}
					return b.created_at - a.created_at;
				})
				.map(order => ({
					...order,
					items: order.items.sort((a, b) => {
						return b.local_id.localeCompare(a.local_id);
					})
				})),
		[selection]
	);

	const onAddItem = (data: AddItemPayload) => {
		onAddExternalItem(selection, data, form);
	};

	return (
		<Drawer.Root
			snapPoints={slider.snapPoints}
			activeSnapPoint={slider.activeSnapPoint}
			open
			setActiveSnapPoint={slider.setActiveSnapPoint}
			dismissible={false}
			handleOnly
			modal={false}
		>
			<Drawer.Portal container={document.body}>
				<Transition
					show={slider.isFullOpen}
					enter="transition-opacity duration-300 ease-in"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-300 ease-out"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<button
						className="fixed inset-0 z-20 bg-black/40"
						aria-label="Close drawer"
						onClick={slider.closeDrawer}
					/>
				</Transition>
				<Drawer.Content
					ref={slider.ref}
					className="fixed flex flex-col bg-white border border-gray-200 border-b-none rounded-t-[10px] bottom-0 left-0 right-0 h-full z-30 outline-none shadow-[0_3px_34px_-15px_rgba(0,0,0,0.3)]"
				>
					<Drawer.Title className="sr-only">Order request builder</Drawer.Title>
					<Drawer.Description className="sr-only">
						Build orders from available supply
					</Drawer.Description>
					{cloneElement(<Drawer.Handle />, {
						preventCycle: true,
						className: 'w-12 h-1.5 mt-2 bg-gray-200 rounded-full',
						onDoubleClick: () => {
							/** removes broken close logic */
						}
					})}
					<div
						className={tlsx({ 'overflow-y-auto': slider.isOpen })}
						style={{ height: slider.scrollHeight }}
					>
						<div className="w-full px-6 pb-6 mx-auto max-w-7xl ">
							<SupplySummaryToolbar
								className="pt-2 pb-4 border-b bg-white sticky top-0 z-10"
								isExanded={slider.isOpen}
								totalCost={totalPrice}
								totalParts={totalItems}
								onClose={slider.closeDrawer}
								onSave={form.handleSubmit(onSave)}
								onViewRequest={slider.openDrawer}
							/>
							<form className="grid grid-cols-10 gap-6 mt-6" noValidate>
								<div className="col-span-7 space-y-6">
									{ordersList.length === 0 ? (
										<EmptyState>
											<EmptyState.Title>No requests</EmptyState.Title>
											<EmptyState.Description>
												Start by selecting parts from the supply options
											</EmptyState.Description>
										</EmptyState>
									) : (
										ordersList.map(order => (
											// todo: use controller / form control to make
											// this more performant. Controller has bug with stale data,
											// currently re-renders all the time :(
											// will circle back to fix.
											<OrderRequestBuilder
												key={order.local_id}
												model={order}
												onCancel={onCancel}
												onChange={val => {
													if (!val) {
														delete selection.draft_orders[order.local_id];
														form.setValue(`draft_orders`, selection.draft_orders);
														return;
													}

													form.setValue(`draft_orders.${order.local_id}`, val);
												}}
												onAddItem={onAddItem}
											/>
										))
									)}
									<OrderRequestNoSupply noSupply={noSupply} onAddItem={onAddItem} />
								</div>
								<ConfirmationAside
									className="self-start col-span-3"
									form={form}
									job={job}
									totalItems={totalItems}
									totalPrice={totalPrice}
									selection={selection}
									onRequestOrders={onRequestOrders}
								/>
							</form>
						</div>
					</div>
				</Drawer.Content>
			</Drawer.Portal>
		</Drawer.Root>
	);
};

type ConfirmationAsideProps = InheritableElementProps<
	'aside',
	{
		job: Job;
		form: UseFormReturn<DraftOrderSelection>;
		selection: DraftOrderSelection;
		totalItems: number;
		totalPrice: number;
		onRequestOrders: (data: DraftOrderSelection) => void;
	}
>;

const ConfirmationAside = ({
	form,
	selection,
	job,
	totalItems,
	totalPrice,
	className,
	onRequestOrders,
	...rest
}: ConfirmationAsideProps) => {
	const [addExternalItem, setAddExternalItem] = useState(false);

	const latestDeliveryDate = useMemo(
		() => getLatestPossibleDeliveryDateFromSelection(selection),
		[selection]
	);

	const onAddItem = (data: AddItemPayload) => {
		setAddExternalItem(false);
		onAddExternalItem(selection, data, form);
	};

	const confirmState = useMemo(() => {
		// todo: cleanup
		const values = Object.values(selection.draft_orders).filter(item => {
			// You can't send finalised orders again
			if (item.status === 'Finalised') {
				return false;
			}

			// Cancelled orders can't be sent
			if (typeof item.status === 'object' && 'Cancelled' in item) {
				return false;
			}

			if (item.items.every(item => typeof item.status === 'object' && 'Rejected' in item.status)) {
				return false;
			}

			return true;
		});

		if (values.every(item => item.status === 'Processed')) {
			return { title: 'Send Orders', disabled: false };
		}

		return {
			title: 'Send Order Requests',
			disabled: !values.some(order => order.status === 'Draft' || order.status === 'Processed')
		};
	}, [selection]);

	return (
		<aside className={tlsx('p-6 space-y-4 border rounded-md', className)} {...rest}>
			<div className="flex items-center justify-between">
				<h1 className="text-lg font-semibold">Order request</h1>
				<Button size="xs" variant="default" onClick={() => setAddExternalItem(true)}>
					Add item
				</Button>
			</div>
			<VehicleDetails className="p-3 border rounded-md" vehicle={job.vehicle} />
			<dl className="p-3 border rounded-md">
				<dt className="text-sm font-medium text-gray-900">Repairhub Europe</dt>
				<dd className="mt-1 text-sm text-gray-700">{formatAddress(selection.delivery_location)}</dd>
			</dl>
			<Controller
				control={form.control}
				name="delivery_date"
				render={({ field }) => (
					<DateTimePicker
						label="Delivery date"
						disabled={confirmState.disabled}
						minDate={latestDeliveryDate}
						{...field}
						// typescript being weird, don't have
						// time to investigate
						onPointerLeaveCapture={undefined}
						onPointerEnterCapture={undefined}
						placeholder={undefined}
					/>
				)}
			/>
			{/* <Controller
        control={form.control}
        name="attempt_auto_transition_order"
        render={({ field: { value, ...rest } }) => (
          <Switch
            disabled={confirmState.disabled}
            label="Automatically send orders once confirmed"
            checked={value}
            {...rest}
          />
        )}
      /> */}
			<dl className="p-3 space-y-2 border rounded-md">
				<div className="flex items-center justify-between text-sm text-gray-700">
					<dt>Items</dt>
					<dd>{totalItems}</dd>
				</div>
				<div className="flex items-center justify-between text-sm font-medium text-gray-900">
					<dt>Total cost</dt>
					<dd>{formatPrice(totalPrice)}</dd>
				</div>
			</dl>
			<Button
				type="submit"
				className="w-full"
				onClick={form.handleSubmit(onRequestOrders)}
				disabled={confirmState.disabled}
			>
				{confirmState.title}
			</Button>
			<AddExternalItemModal
				open={addExternalItem}
				onClose={() => setAddExternalItem(false)}
				onSubmit={onAddItem}
			/>
		</aside>
	);
};
