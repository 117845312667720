import { SupplyVendor } from '@/sdk/lib';
import { userQueries } from '@/sdk/react';
import { shipping } from '@/sdk/reflect/reflect';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { AuthenticatedUser } from '../models/user';

// todo: for some reason typescript is unhappy inferring this. don't
// have time to work out why.
export type Site = {
	kind: 'site';
	id: string;
	name: string;
	shipping_address: shipping.ShippingAddress;
	user_groups: Array<string>;
};

export type Company = {
	kind: 'company';
	id: string;
	name: string;
	vendors: Record<string, SupplyVendor>;
	user_groups: Array<string>;
};

export const useAuthenticatedUser = () => {
	const { data } = useSuspenseQuery({
		...userQueries.get(),
		// Stop refetching every time a new component uses
		// this hook. This is a network performance optimization.
		refetchOnMount: false,
		// Refetch when the window regains focus, this will
		// ensure things like permissions can be updated if needed.
		refetchOnWindowFocus: true
	});

	if (!data.user) {
		throw new Error('Cannot use me hook without user data');
	}

	if ('guest' in data.user) {
		throw new Error('Unsupported user type');
	}

	const { account } = data.user;

	const user = useMemo<AuthenticatedUser>(() => {
		return {
			first_name: account.firstName,
			surname: account.lastName,
			email: account.email,
			business_entities: data.business_entities
		};
	}, [account]);

	const vendors = useMemo(() => {
		// this logic isn't really correct. It assumes that a user is only in one
		// company. At this time, it is unclear if that is the case.
		const companies = data.business_entities.filter(
			entity => entity.kind === 'company'
		) as Company[];
		const companyVendors = companies.reduce(
			(acc, company) => {
				const companyVendors = Object.entries(company.vendors);
				for (const [key, vendor] of companyVendors) {
					acc[key] = {
						id: key,
						name: vendor.name
					};
				}

				return acc;
			},
			{} as Record<string, SupplyVendor>
		);

		return Object.values(companyVendors);
	}, [data.business_entities]);

	const sites = useMemo<Site[]>(() => {
		return data.business_entities.filter(entity => entity.kind === 'site') as Site[];
	}, [data.business_entities]);

	return { user, vendors, sites };
};
